import { Module, VuexModule, Mutation, config, Action } from 'vuex-module-decorators'
import { GisItem } from '@/interfaces/gis.inteface'
import { IReport, ReportEventsItem, ReportInfo, ReportStatusItem, ViolationTypeItem } from '@/interfaces/report.interface'
import * as api from '@/https/api'
import { getRegionId } from '@/config'

config.rawError = true

export interface PbBarItem {
  color: string,
  percent: number,
  status: string,
  event: string,
  days: number,
  fontColor: string,
  icon: string
}

export const REPORT_NAME = 'report'

export interface GetItemByNameFn {
  (name: string): GisItem | null;
}

@Module({ namespaced: true, name: REPORT_NAME })
export default class ReportModule extends VuexModule {
  public _reportList: IReport[] = [];
  public _reportInfo!: ReportInfo;

  public reportStatusList: ReportStatusItem[] = []
  public reportEventsList: ReportEventsItem[] = []
  public violationTypeList: ViolationTypeItem[] = []
  private _autoReportMap: Map<string, Record<string, any>> = new Map([
    ['NX_202401160022', {
      litigant: {
        violation_type: '公共管理与公共服务用地/公园与绿地用地/破坏一般耕地行为',
        violation_rule: '《中华人民共和国土地管理法》第三十七条：非农业建设必须节约使用土地，可以利用荒地的，不得占用耕地；可以利用劣地的，不得占用好地。禁止占用耕地建窑、建坟或者擅自在耕地上建房、挖砂、采石、采矿、取土等。禁止占用永久基本农田发展林果业和挖塘养鱼。',
        name: '王海',
        type: '个人',
        mobile: '18945878565',
        purpose: '疑似挖鱼塘'
      }
    }],
    ['NX_202311130052', {
      litigant: {
        violation_type: '公共管理与公共服务用地/公园与绿地用地/破坏基本农田行为',
        violation_rule: ' 《基本农田保护条例》第十七条：禁止任何单位和个人在基本农田保护区内建窑、建房、建坟、挖砂、采石、采矿、取土、堆放固体废弃物或者进行其他破坏基本农田的活动。禁止任何单位和个人占用基本农田发展林果业和挖塘养鱼。',
        name: '陈山',
        type: '个人',
        mobile: '15878894510',
        purpose: '私自挖坟'
      }
    }],
    ['NX_202311130036', {
      litigant: {
        violation_type: '公共管理与公共服务用地/公园与绿地用地/未经批准或者采取欺骗手段骗取批准，违法占用土地',
        violation_rule: '建设占用土地，涉及农用地转为建设用地的，应当办理农用地转用审批手续。永久基本农田转为建设用地的，由国务院批准。在土地利用总体规划确定的城市和村庄、集镇建设用地规模范围内，为实施该规划而将永久基本农田以外的农用地转为建设用地的，按土地利用年度计划分批次按照国务院规定由原批准土地利用总体规划的机关或者其授权的机关批准。在已批准的农用地转用范围内，具体建设项目用地可以由市、县人民政府批准。在土地利用总体规划确定的城市和村庄、集镇建设用地规模范围外，将永久基本农田以外的农用地转为建设用地的，由国务院或者国务院授权的省、自治区、直辖市人民政府批准。',
        name: '李三风',
        type: '个人',
        mobile: '15878894510',
        purpose: '建设占用耕地'
      }
    }]
  ])

  get autoReportMap (): Map<string, PbBarItem> {
    return new Map([
      ['NX_202401160022',
        {
          color: '#09E075',
          percent: 15,
          status: 'normal', // exception,normal,success
          event: '办理中，剩', // 超期，办理中，
          days: 12,
          fontColor: '#fff',
          icon: '/work/images/icons/icon_info_green.png'
        }
      ], [
        'NX_202311130052',
        {
          color: '#FFF217',
          percent: 60,
          status: 'normal',
          event: '办理中，剩',
          days: 7,
          fontColor: '#444',
          icon: '/work/images/icons/icon_info_yellow.png'
        }
      ], [
        'NX_202311130036',
        {
          color: '#FF3E64',
          percent: 100,
          status: 'exception',
          event: '已超期',
          days: 24,
          fontColor: '#fff',
          icon: '/work/images/icons/icon_info_red.png'
        }
      ]
    ])
  }

  get reportList () {
    return this._reportList
  }

  get reportInfo () {
    const numberList = Array.from(this._autoReportMap.keys())
    if (numberList.includes(this._reportInfo.number)) {
      this._reportInfo.litigant = this._autoReportMap.get(this._reportInfo.number)?.litigant
    }
    return this._reportInfo
  }

  get reportStatus () {
    return this.reportStatusList
  }

  get reportEvents () {
    return this.reportEventsList
  }

  get violationType () {
    return this.violationTypeList
  }

  @Mutation
  setViolationType (types: ViolationTypeItem[]) {
    this.violationTypeList = types
  }

  @Mutation
  setReportStatus (status: ReportStatusItem[]) {
    this.reportStatusList = status
  }

  @Mutation
  setReportEvents (events: ReportEventsItem[]) {
    this.reportEventsList = events
  }

  @Mutation
  setReportList (reportList: IReport[]) {
    this._reportList = reportList
  }

  @Mutation
  setReportInfo (info: ReportInfo) {
    this._reportInfo = info
  }

  @Action({})
  async fetchReportList (params: any) {
    let { data } = await api.getTaskList({ ...params, region_id: getRegionId() })
    data = data.sort((a, b) => b.id - a.id)
    this.setReportList(data)
  }

  @Action({})
  async fetchReportInfo (report_id: number) {
    const { data } = await api.getReportInfo({ report_id })
    data.hotspotGroups = data.hotspotGroups.reverse()
    this.setReportInfo(data)
    return data
  }
}
